import React, {useEffect, useState} from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import { TbMathOff } from "react-icons/tb";


let typeWriter;
const typeText = "Enhance your dating life"
function Index(props) {

    const [counter, setCounter] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })

    useEffect(() => {
        const homeComing = 1735132900000

        const currentTime = Date.now()
        
        const timeLeft = homeComing - currentTime

        setCounter((prevCounter) => {
            const leftTimeDays = timeLeft-Math.floor(timeLeft/86400000)* 86400000
            const leftTimeHours = leftTimeDays-Math.floor(leftTimeDays/3600000) * 3600000;
            const leftTimeMinutes = leftTimeHours-Math.floor(leftTimeHours/60000) * 60000

            let newCount = {
                days: Math.floor(timeLeft/86400000),
                hours: leftTimeDays/3600000,
                minutes: Math.floor(leftTimeHours/60000),
                seconds: Math.floor(leftTimeMinutes/1000),

            }
            console.log(newCount)
            return newCount
            
            
            


        })           
        
        setInterval(() => {
            
        const homeComing = 1735132900000

        const currentTime = Date.now()
        
        const timeLeft = homeComing - currentTime

        setCounter((prevCounter) => {
            const leftTimeDays = timeLeft-Math.floor(timeLeft/86400000)* 86400000
            const leftTimeHours = leftTimeDays-Math.floor(leftTimeDays/3600000) * 3600000;
            const leftTimeMinutes = leftTimeHours-Math.floor(leftTimeHours/60000) * 60000;

            let newCount = {
                ...prevCounter,
                hours: Math.floor(leftTimeDays/3600000),
                minutes: Math.floor(leftTimeHours/60000),
                seconds: Math.floor(leftTimeMinutes/1000),

            }
            console.log(newCount)
            return newCount
            
            
            


        })   


        },1000)
        





    }, [])
    


    useEffect(() => {
        let element = document.getElementById('mainText')
        let i = -1
        typeWriter = setInterval(() => {
            if (i < typeText.length-1) {
                i++
                let prevText = element.textContent

                prevText += typeText[i]
                element.innerText = prevText
            } else {
                clearInterval(typeWriter)
            }
            
        }, 30)
        
      
        // return (
        //     // clearInterval(typeWriter)
        // )
    }, [])



    return (
        <>
        <section data-theme='cupcake'>
        <Navbar />

     
        <div className="hero bg-base-100 min-h-[80vh]">

            <div className="hero-content flex-col text-center p-5">
              
                <p id="mainText" className=" font-1 sm:text-8xl text-5xl font-bold"></p>
                <p className="sm:text-2xl text-lg font-2">and setup your friends 😉 with <strong>Laylnk</strong></p>
                


                <a href="/signup" className="btn btn-secondary btn-wide btn-lg">
                    <p className="text-base-100 font-2 ">Join Now</p>
                </a>
               
                
                

            </div>
            
        </div>
       

       
            

        </section>
        <section className="min-h-[40vh] bg-base-300" data-theme="dark">

            <div className=" flex-col justify-center">
                <div className="w-full h-full sm:p-20 p-4 text-center bg-base-200 flex flex-col items-center gap-4">
                <div className="">
                    <p className="font-2 sm:text-4xl text-3xl font-bold">The Holiday Season is fast approaching</p>
                </div>
                
                <div>
                <div className="flex items-center justify-items-center gap-4 flex-row  font-2 bg-base-200">
  <div>
    <span className="font-mono text-6xl countdown">
        <span style={{"--value": counter.days}}></span>
    </span> 
    days
  </div>
  <div>
    <span className="font-mono text-6xl countdown">
        <span style={{"--value": counter.hours}}></span>
    </span> 
    hours
  </div>
  <div>
    <span className="countdown font-mono text-6xl">
      <span style={{"--value":counter.minutes}}></span>
    </span>
    min
  </div>
  <div>
    <span className="countdown font-mono text-6xl">
      <span style={{"--value": counter.seconds}}></span>
    </span>
    sec
  </div>
</div>
                </div>
            


                
                <p className="font-1 font-bold text-2xl sm:mt-24 mt-12">Don't be that one person without matching pjs🤡</p>     

                </div>
                           
            </div>
            




            {/* <div className="p-4">
                <div className="sm:w-5/6 w-full bg-primary mx-auto rounded-lg block ">
                <div className="border-b-2 py-4  mb-4">
                    <div className="p-4 rounded-lg bg-base-300 w-fit mx-auto text-white">
                        <p className="text-center text-4xl font-1 font-bold   rounded-t-lg">New Features</p>
                        <p className="text-center text-base  font-semibold">updated daily</p>
                    </div>
                
                </div>

                <div className="sm:hidden flex w-full flex-col gap-2 items-center justify-items-center text-white">
                    
                    <div className="bg-base-100 p-4  w-fit m-2 rounded-lg">
                    <p className="card-title w-fit text-3xl p-4 bg-primary rounded-lg shadow-sm">🔌</p>
                            <p className="font-1 text-lg font-bold">Connects with other apps</p>
                            <p className="text-sm font-1 font-semibold">When you match, they will be given your social media instead of messaging on Laylnk.</p>
                    </div>
                    <div className="bg-base-100 p-4 w-fit m-2 rounded-lg">
                    <p className="card-title w-fit text-3xl p-4 bg-primary rounded-lg shadow-sm">🚀</p>
                            <p className="font-1 text-lg font-bold">Limited swipes per day</p>
                            <p className="text-sm font-1 font-semibold">To have people spend more time per profile, <br/>people will be limited to 1 swipe per day (unless they are setup with someone)</p>
                       </div>
                       <div className="bg-base-100 p-4 w-5/6 m-2 rounded-lg">
                    <p className="card-title w-fit text-3xl p-4 bg-primary rounded-lg shadow-sm">💘</p>
                            <p className="font-1 text-lg font-bold">Setup your friends</p>
                            <p className="text-sm font-1 font-semibold">Send people on your feed to your friends.</p>
                    </div>
                </div>
                

                {/* <div class="container p-4 w-full h-full sm:block hidden  rounded-lg shadow-md overflow-y-hidden">
                    <div class="items mb-2 rounded-lg">
                        <div class="item"><div className="card bg-base-100 text-white">
                        <div className="card-body">
                            <p className="card-title w-fit text-3xl p-4 bg-primary rounded-lg shadow-sm">💘</p>
                            <p className="font-1 text-lg font-bold">Setup your friends</p>
                            <p className="text-sm font-1 font-semibold">Send people on your feed to your friends.</p>
                        </div>
                    
                   
                    </div></div>
                        <div class="item"><div className="card rounded-lg  bg-base-100 text-white">
                        <div className="card-body">
                            <p className="card-title w-fit text-3xl p-4 bg-primary rounded-lg shadow-sm">🔌</p>
                            <p className="font-1 text-lg font-bold">Connects with other apps</p>
                            <p className="text-sm font-1 font-semibold">When you match, they will be given your social media instead of messaging on Laylnk.</p>
                        </div>
                    
                   
                    </div></div>
                        <div class="item"><div className="card rounded-lg  bg-base-100 text-white">
                        <div className="card-body">
                            <p className="card-title w-fit text-3xl p-4 bg-primary rounded-lg shadow-sm">🚀</p>
                            <p className="font-1 text-lg font-bold">Limited swipes per day</p>
                            <p className="text-sm font-1 font-semibold">To have people spend more time per profile, <br/>people will be limited to 1 swipe per day (unless they are setup with someone)</p>
                        </div>
                    
                   
                    </div></div>
                        
                    </div>
                </div> */}

                
            {/* </div> */}

            {/* </div> */}
        
           
        </section>

        {/* <section data-theme="cupcake" className="bg-base-100 p-10 min-h-[60vh]">
            <p className="font-1 font-semibold text-4xl">Footprint Free Dating at your <span className="font-bold">fingertips</span></p>
            <p className="font-1 font-semibold"></p>
            <div className="grid grid-cols-3 items-center justify-items-center mt-5 gap-4">
                <div className="p-4 bg-base-300 w-full">
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-12">
  <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
</svg>
                </div>
               
                    <div className="mt-4 ml-2">
                        <p className="font-2 font-bold text-2xl">Remove your account at anytime</p>
                        <p className="text-lg font-2">Laylnk's </p>
                    </div>
                    

                    

                </div>
                <div className="p-4 bg-base-300 w-full">
                    <p>Remove your account at <bold>anytime</bold></p>

                    

                </div>
                <div className="p-4 bg-base-300 w-full">
                    <p>Remove your account at <bold>anytime</bold></p>

                    

                </div>
                
            </div>

        </section> */}
        


        <div className="">
            <Footer />
        </div>
        </>
    )
}


export default Index